@import "../../assets/scss/variables.scss";

body {
  background: #070707;
}

html {
  overflow-x: hidden;
}

#root {
  width: 100%;
  background: #000E0E;
}

.container {
  width: 1440px;
  max-width: 94%;
  margin: auto;

  @media screen and (max-width:991px) {
    max-width: 92%;
  }
}

.headings {
  text-align: center;
  margin-bottom: 2rem;

  h1 {
    color: $primary-color;
    font-size: 4rem;
    margin-bottom: 0.5rem;

    @media screen and (max-width:1600px) {
      font-size: 3.6rem;
      margin-bottom: 0.3rem;
    }

    @media screen and (max-width:1400px) {
      font-size: 3rem;
      margin-bottom: 0.2rem;
    }

    @media screen and (max-width:1024px) {
      font-size: 3rem;
    }

    @media screen and (max-width:991px) {
      font-size: 2.5rem;
    }

    @media screen and (max-width:767px) {
      font-size: 2rem;
      line-height: 1.2;
    }

    span {
      color: $primary-color;
    }
  }

  p {
    font-family: $font-secondary;
    color: $white-color;
    font-size: 1.5rem;

    @media screen and (max-width:1600px) {
      font-size: 1.1rem;
    }

    @media screen and (max-width:1400px) {
      font-size: 1rem;
    }

    @media screen and (max-width:1024px) {
      font-size: 1rem;
    }

    @media screen and (max-width:767px) {
      font-size: 0.90rem;

      br {
        display: none;
      }
    }
  }
}

.hero_section_main_container {
  width: 100%;
  position: relative;
  min-height: 900px;
  display: flex;
  background: #000E0E;

  @media screen and (max-width:1660px) {
    min-height: 700px;
  }

  @media screen and (max-width:1400px) {
    min-height: 600px;
  }

  @media screen and (max-width:1024px) {
    min-height: 600px;
  }

  .banner-tag {
    position: absolute;
    right: 0;
    background: url('../../assets/images/banner-tag.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 85px;
    bottom: 14%;
    z-index: 2;
    font-family: $font-secondary;
    font-weight: 300;
    color: $white-color;
    padding: 1rem 1.4rem;
    font-size: 24px;
    width: 100%;
    max-width: 320px;

    @media screen and (max-width:1024px) {
      font-size: 20px;
      height: 65px;
      padding: 0.5rem 1rem;
      line-height: 1.2;
    }

    @media screen and (max-width:640px) {
      font-size: 18px;
      height: 55px;
      max-width: 200px;
    }

    @media screen and (max-width:480px) {
      font-size: 16px;
    }
  }

  .background-img {
    position: absolute;
    width: 100vw;
    height: calc(100% - 42px);
    object-fit: cover;
  }

  .hero_section_container {
    height: 100%;
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    .text_button_container {
      height: 100%;
      width: 100%;

      .headings {
        text-align: left;

        p {
          font-size: 4rem;
          text-transform: uppercase;

          @media screen and (max-width:1024px) {
            font-size: 3rem;
          }

          @media screen and (max-width:640px) {
            font-size: 2.4rem;
            max-width: 300px;
          }

          @media screen and (max-width:480px) {
            font-size: 2rem;
          }
        }

        h1 {
          font-size: 7.5rem;
          line-height: 0.9;
          margin-bottom: 0;
          color: $white-color;

          @media screen and (max-width:1400px) {
            font-size: 4rem;
          }

          @media screen and (max-width:1024px) {
            font-size: 4rem;
            line-height: 1.1;
          }

          @media screen and (max-width:640px) {
            font-size: 3rem;
          }

          @media screen and (max-width:480px) {
            font-size: 2.4rem;
          }

          span {
            color: $primary-color;
          }

        }
      }

      .comming-soon {
        display: flex;
        align-items: center;
        justify-content: center;
        color: $white-color;
        font-size: 1.8rem;
        font-weight: normal;
        font-family: $font-secondary;

        @media screen and (max-width:640px) {
          font-size: 1.3rem;
        }

        svg {
          margin-right: 8px;

          @media screen and (max-width:640px) {
            margin-right: 6px;
          }
        }
      }
    }
  }


  .bar-bg-bottom {
    position: absolute;
    bottom: 15px;
    width: 100%;
    background: $primary-color;
    background-repeat: repeat-x;
    height: 42px;
    transform: rotate(1deg);

    .scroller__inner {
      display: flex;
      flex-wrap: wrap;

      div {
        background: url('data:image/svg+xml,<svg width="13" height="11" viewBox="0 0 13 11" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M6.20052 0.143864C6.37892 0.14668 6.5423 0.244346 6.62925 0.400147L11.9786 9.98582C12.0657 10.1419 12.0628 10.3326 11.9711 10.486C11.8794 10.6394 11.7128 10.7322 11.5341 10.7294L0.535462 10.5557C0.356745 10.5529 0.193127 10.4549 0.106306 10.2986C0.0194847 10.1424 0.022667 9.95171 0.114653 9.79846L5.76393 0.386483C5.85575 0.233505 6.02213 0.141047 6.20052 0.143864ZM6.63779 4.15087C6.64192 3.87476 6.42145 3.64757 6.14534 3.64343C5.86922 3.63929 5.64204 3.85977 5.6379 4.13588L5.60043 6.6356C5.59629 6.91171 5.81677 7.1389 6.09288 7.14304C6.36899 7.14718 6.59618 6.9267 6.60032 6.65059L6.63779 4.15087ZM6.57463 8.15075C6.57898 7.87465 6.35868 7.64729 6.08257 7.64294C5.80646 7.63858 5.57911 7.85889 5.57475 8.13499L5.57081 8.38496C5.56646 8.66107 5.78676 8.88843 6.06287 8.89278C6.33898 8.89713 6.56634 8.67683 6.57069 8.40072L6.57463 8.15075Z" fill="%230C0C0C"/></svg>');
        background-repeat: no-repeat;
        background-position: 100% center;
        display: inline-block;
        background-size: 20px;
        font-size: 2.1rem;
        text-transform: uppercase;
        font-weight: 400;
        padding-right: 3rem;
        padding-left: 1.8rem;
        height: 42px;
      }
    }

    .scroller[data-animated="true"] {
      overflow: hidden;
    }

    .scroller[data-animated="true"] .scroller__inner {
      width: max-content;
      flex-wrap: nowrap;
      animation: scroll var(--_animation-duration, 40s) var(--_animation-direction, forwards) linear infinite;
    }

    .scroller[data-direction="right"] {
      --_animation-direction: reverse;
    }

    .scroller[data-speed="fast"] {
      --_animation-duration: 30s;
    }

    .scroller[data-speed="slow"] {
      --_animation-duration: 60s;
    }

    @keyframes scroll {
      to {
        transform: translate(calc(-50% - 0.5rem));
      }
    }

  }
}

@keyframes scroll-horizontal {
  0% {
    left: 100%;
  }

  100% {
    left: -100%;
  }
}

.ourecosystem-section {
  padding: 5rem 0 5rem 0;
  background-size: 100%;
  position: relative;

  @media screen and (max-width:767px) {
    padding: 3rem 0;
  }

  .container {
    position: relative;
    z-index: 1;
  }

  .infrastructure-row {
    background: url('../../assets/images/infa-bg.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;

    @media screen and (max-width:767px) {
      background: none;
      text-align: center;
    }

    .infra-title {
      margin-top: 0.3rem;
      width: 53%;

      @media screen and (max-width:767px) {
        text-align: center;
        margin: auto auto 1rem auto;
        width: 70%;
      }
    }

    .infrastructure-row-inner {
      padding: 6rem;
      display: flex;
      justify-content: space-between;

      @media screen and (max-width:767px) {
        flex-direction: column;
        padding: 0;
      }

      p {
        font-size: 1.55rem;
        line-height: 1.6;
        color: $white-color;
        max-width: 450px;
        font-weight: 500;
        font-family: $font-secondary;
        margin-top: 8%;

        @media screen and (max-width:767px) {
          font-size: 1.1rem;
          text-align: center;
          margin-bottom: 1.5rem;
          margin-top: 0;
        }
      }

      .right-animation {
        max-width: 460px;

        @media screen and (max-width:767px) {
          background: url('../../assets/images/infa-bg-mobile.png');
          padding: 6rem 3rem;
          max-width: 100%;
          background-size: 100% 100%;
        }

        img {
          width: 100%;
        }
      }
    }
  }
}

.community-section {
  padding: 4rem 0 10rem 0;
  background: url('../../assets/images/communitybg.png');
  background-size: cover;
  position: relative;
  background-position: left -6.5%;
  background-repeat: no-repeat;


  @media screen and (max-width:991px) {
    padding: 6rem 0;
  }

  @media screen and (max-width:600px) {
    text-align: center;
    padding: 3rem 0 6rem 0;
  }



  .headings {
    text-align: left;

    @media screen and (max-width:600px) {
      text-align: center;
    }

    h1 {
      color: #000000;
      line-height: 1;
    }

    p {
      color: #000000;
      font-weight: 500;
      max-width: 450px;
    }
  }


  .community-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    @media screen and (max-width: 991px) {
      flex-direction: column-reverse;
      gap: 6rem;

    }

    .headings {
      @media screen and (max-width: 991px) {
        padding: 0;
        margin-bottom: 0rem;

        h1 {

          font-size: 62px !important;
        }

      }
    }

    .community-left {

      @media screen and (max-width: 991px) {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 2rem;
      }
    }

    .community-right {


      @media screen and (max-width: 991px) {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;
      }
    }
  }


  .community-list {
    display: flex;
    grid-gap: 2rem;

    a {
      transition: all ease-in 0.1s;

      &:hover {
        transform: scale(1.05);
      }
    }
  }
}

.buildowngame-section {
  padding: 10rem 0;
  background-size: 100%;
  position: relative;

  @media screen and (max-width:1400px) {
    padding: 6rem 0;
  }

  .container {
    position: relative;
    z-index: 1;
  }

  .buildowngame-circle {
    position: absolute;
    width: 552px;
    height: 552px;
    max-width: 100vw;
    left: 0;
    right: 0;
    margin: auto;
    background: #00E1FF;
    opacity: 0.5;
    -webkit-filter: blur(400px);
    -moz-filter: blur(400px);
    filter: blur(400px);
    top: 0;
  }

  .buildowngame-row {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1.8rem;
    padding-top: 3rem;

    @media screen and (max-width:991px) {
      grid-template-columns: repeat(2, 1fr);
      padding-top: 1rem;
    }

    @media screen and (max-width:640px) {
      grid-template-columns: repeat(1, 1fr);
    }

    .buildowngame-item {
      background: url('../../assets/images/buildcard-bg.png');
      background-size: 100% 100%;
      min-height: 205px;
      padding: 2rem;
      position: relative;

      @media screen and (max-width:640px) {
        min-height: auto;
        padding: 1.5rem;
        background: url('../../assets/images/buildcard-bg-mobile.png');
        background-size: 100% 100%;
      }

      .toggle-icon {
        position: absolute;
        top: 28px;
        right: 20px;
        background: url('data:image/svg+xml,<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14 7.99805H8V13.998H6V7.99805H0V5.99805H6V-0.00195312H8V5.99805H14V7.99805Z" fill="white"/></svg>');
        display: block;
        z-index: 1;
        width: 15px;
        height: 15px;
        background-size: 100%;
        transition: all ease-in 0.1s;

        &.active {
          transform: rotate(45deg);
        }
      }

      h3 {
        color: $white-color;
        font-weight: 600;
        font-size: 1.6rem;
        letter-spacing: 1.5px;
        margin-bottom: 0.4rem;

        @media screen and (max-width:991px) {
          font-size: 1.2rem;
        }

        @media screen and (max-width:640px) {
          margin-bottom: 0;
        }

        span {
          color: $primary-color;
        }
      }

      p {
        color: $white-color;
        font-size: 1rem;
        font-family: $font-secondary;
        transition: all ease-in 0.1s;

        @media screen and (max-width:991px) {
          font-size: 0.85rem;
        }

        @media screen and (max-width:640px) {
          height: 0;
          overflow: hidden;
          opacity: 0;
        }

        a {
          color: #52F7FF;
          text-decoration: none;
        }
      }

      &.active {
        background: url('../../assets/images/buildcard-bg-mobile-active.png');

        p {
          @media screen and (max-width:640px) {
            height: auto;
            overflow: initial;
            opacity: 1;
            margin-top: 0.5rem;
          }
        }
      }

    }
  }
}

.gamefi-section {
  padding: 8rem 0 10rem 0;
  position: relative;

  @media screen and (max-width:1400px) {
    padding: 6rem 0;
  }

  .container {
    position: relative;
    z-index: 1;
  }

  .gamefisection-row {
    display: flex;
    grid-gap: 15px;
    max-width: 1080px;
    margin: auto;

    @media screen and (max-width:600px) {
      flex-direction: column;
    }

    .card-img {
      width: 100%;
    }

    p {
      position: absolute;
      color: $white-color;
      font-family: $font-secondary;
      font-size: 20px;
      margin: 0;

      @media screen and (max-width:991px) {
        font-size: 0.9rem;
      }
    }

    .left-colum {
      width: 68%;

      @media screen and (max-width:600px) {
        width: 100%;
        display: flex;
        flex-direction: column-reverse;
        grid-gap: 1rem;
      }

      .left-colum-row1 {
        display: flex;
        width: 100%;
        grid-gap: 15px;

        @media screen and (max-width:600px) {
          flex-direction: column;
        }

        .item-1 {
          width: 34%;
          position: relative;

          @media screen and (max-width:600px) {
            width: 100%;
          }

          p {
            max-width: 60%;
            top: 20%;
            left: 10%;
          }
        }

        .item-2 {
          width: 66%;
          position: relative;

          @media screen and (max-width:600px) {
            width: 100%;
          }

          p {
            max-width: 50%;
            top: 20%;
            left: 10%;
          }

          .img2 {
            position: absolute;
            bottom: 14px;
            right: 2px;
            max-width: 200px;

            @media screen and (max-width:991px) {
              max-width: 40%;
            }
          }
        }
      }

      .item-3 {
        margin-top: -13%;
        position: relative;

        @media screen and (max-width:600px) {
          margin: 0;
        }

        p {
          color: #070707;
          max-width: 40%;
          right: 8%;
          bottom: 20%;
          font-weight: 500;
          font-size: 1.5rem;

          @media screen and (max-width:991px) {
            font-size: 1.2rem;
          }

          @media screen and (max-width:767px) {
            bottom: 14%;
            font-size: 1rem;
          }
        }

        h2 {
          position: absolute;
          font-size: 4rem;
          top: 18%;
          left: 10%;

          @media screen and (max-width:991px) {
            font-size: 3rem;
          }

          @media screen and (max-width:767px) {
            font-size: 2.2rem;
          }
        }
      }
    }

    .right-colum {
      width: 31%;
      display: flex;
      flex-direction: column;
      grid-gap: 15px;

      @media screen and (max-width:600px) {
        width: 100%;
      }

      .item-1 {
        position: relative;

        p {
          max-width: 50%;
          top: 26%;
          left: 10%;
        }
      }

      .item-2 {
        margin-top: -0.5rem;
        position: relative;

        p {
          max-width: 60%;
          top: 30%;
          left: 10%;
        }
      }

      .item-3 {
        margin-top: -2rem;
        position: relative;

        p {
          max-width: 60%;
          top: 50%;
          left: 10%;
        }

        .img2 {
          position: absolute;
          bottom: 10px;
          right: 2px;
          max-width: 120px;

          @media screen and (max-width:991px) {
            max-width: 40%;
          }
        }
      }
    }
  }

}


.prediction-section {
  padding: 8rem 0 8rem 0;
  position: relative;

  .container {
    position: relative;
    z-index: 1;

    @media screen and (max-width:767px) {
      max-width: 100%;
      padding-left: 4%;
    }
  }

  .prediction-section-row {
    @media screen and (max-width:767px) {
      display: flex;
      width: 100%;
      overflow-x: auto;
    }
  }

  .prediction-upper {
    display: flex;
    grid-gap: 1rem;
    width: 100%;

    @media screen and (max-width:767px) {
      width: auto;
    }

    .cards {
      border: 1px solid rgba($secondary-color, 0.5);
      padding: 2.5rem 2rem;
      min-height: 500px;
      overflow: hidden;
      position: relative;

      @media screen and (max-width:1440px) {
        min-height: 450px;
      }

      @media screen and (max-width:767px) {
        min-height: 380px;
        width: 280px;
        min-width: 280px;
      }

      &.card1 {
        background: url('../../assets/images/frame-bg.png');
        max-width: 45%;
        width: 100%;

        h2 {
          color: $primary-color;
          font-size: 4rem;
          line-height: 0.9;
          margin-bottom: 1.2rem;

          @media screen and (max-width:1024px) {
            font-size: 2.5rem;
          }

          @media screen and (max-width:767px) {
            font-size: 2.1rem;
          }
        }

        p {
          font-family: $font-secondary;
          font-size: 1.5rem;
          color: $white-color;
          max-width: 300px;
          position: relative;
          z-index: 1;
        }

        img {
          max-width: 60%;
          position: absolute;
          right: -15%;
          bottom: -10%;

          @media screen and (max-width:767px) {
            right: -18%;
            bottom: -12%;
            max-width: 50%;
          }
        }
      }

      &.card2 {
        background: url('../../assets/images/frame-bg2.png');
        width: 27.5%;

        h3 {
          font-size: 2.2rem;
          color: $white-color;
          font-weight: 300;
          text-transform: uppercase;
        }

        img {
          max-width: 90%;
          position: absolute;
          right: -15%;
          bottom: 10%;
        }
      }

      &.card3 {
        background: url('../../assets/images/frame-bg.png');
        width: 27.5%;

        h3 {
          font-size: 2.5rem;
          color: $white-color;
          font-weight: 300;
          text-transform: uppercase;
        }

        img {
          max-width: 80%;
          position: absolute;
          right: 0;
          bottom: 0;
        }
      }
    }
  }

  .prediction-bottom {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1rem;
    margin-top: 1rem;

    @media screen and (max-width:767px) {
      display: flex;
      margin-top: 0;
      margin-left: 1rem;
    }

    .cards {
      border: 1px solid rgba($secondary-color, 0.5);
      padding: 2.5rem 2rem;
      min-height: 242px;
      overflow: hidden;
      position: relative;

      @media screen and (max-width:767px) {
        min-height: 300px;
        width: 280px;
        min-width: 280px;
      }

      h3 {
        font-size: 2.2rem;
        color: $white-color;
        font-weight: 300;
        text-transform: uppercase;
        line-height: 1.1;
      }

      &.card1 {
        background: url('../../assets/images/frame-bg2.png');

        img {
          max-width: 180px;
          position: absolute;
          bottom: 0;
          right: 0;
        }
      }

      &.card2 {
        background: url('../../assets/images/frame-bg.png');

        img {
          max-width: 210px;
          position: absolute;
          top: 55%;
          right: -2%;
        }

      }

      &.card3 {
        background: url('../../assets/images/frame-bg2.png');

        img {
          max-width: 100%;
          position: absolute;
          bottom: 0;
          right: 0;
        }
      }
    }
  }
}

.redefined-section {
  padding: 6rem 0 10rem 0;
  position: relative;

  .redefined-circle {
    position: absolute;
    width: 552px;
    height: 552px;
    max-width: 100vw;
    left: 0;
    right: 0;
    margin: auto;
    background: #00E1FF;
    opacity: 0.3;
    filter: blur(400px);
    top: 0;
  }

  .redefined-row {
    display: flex;
    justify-content: space-between;

    @media screen and (max-width:900px) {
      flex-direction: column;
    }

    .redefined-left {
      width: 50%;

      @media screen and (max-width:900px) {
        width: 100%;
      }

      h1 {
        color: $primary-color;
        font-size: 4rem;
        line-height: 1.1;
        margin-bottom: 1.5rem;

        @media screen and (max-width:1400px) {
          font-size: 3rem;
        }
      }

      p {
        font-size: 1.5rem;
        color: $white-color;
        font-family: $font-secondary;
        max-width: 600px;

        @media screen and (max-width:1400px) {
          font-size: 1.2rem;
        }
      }

      ul {
        list-style-type: none;
        padding-left: 0;
        margin-top: 3.2rem;

        li {
          padding: 1rem 0 1rem 3rem;
          color: rgba($white-color, 0.5);
          font-size: 1.8rem;
          position: relative;
          font-weight: 600;
          line-height: 1.3;
          cursor: pointer;

          @media screen and (max-width:1400px) {
            font-size: 1.5rem;
          }

          &::before {
            width: 32px;
            height: 32px;
            content: '';
            position: absolute;
            left: 0;
            background: url('../../assets/images/check.png');
          }

          &.active {
            &::before {
              background: url('../../assets/images/checked.png');
            }

            color: $white-color;
          }
        }
      }
    }

    .redefined-right {
      width: 50%;
      max-width: 578px;

      @media screen and (max-width:900px) {
        width: 100%;
        margin-top: 1.5rem;
      }

      .redefined-right-anim {
        width: 100%;
      }

      .redefined-right-inner {
        background: url('../../assets/images/redefined-frame.png');
        height: 480px;
        background-size: 100% 100%;
        width: 100%;
        text-align: center;
        position: relative;
        text-align: center;

        .bar-slider {
          position: absolute;
          left: -23px;
          top: 0;
          bottom: 0;
          width: 15px;
          transition: all ease-in 0.5s;
          z-index: 2;

          &.bar-slide2 {
            left: calc(100% - 36px);
            right: 21px;
            -webkit-animation: linear infinite;
            -webkit-animation-name: LeftToRight;
          }

          &.bar-slide3 {
            left: calc(100% - 36px);
            right: 21px;
            -webkit-animation: linear infinite;
            -webkit-animation-name: RightToLeft;
          }

          img {
            height: 105%;
          }
        }

        .redefined-imgs {
          object-fit: fill;
          max-height: 100%;
          margin: auto;
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          max-width: 100%;
        }
      }
    }
  }
}

.roadmap-section {
  position: relative;
  padding: 6rem 0 10rem 0;
  color: white;

  h1 {
    color: $primary-color;
    font-size: 4rem;
    line-height: 1.1;
    margin-bottom: 1.5rem;

    @media screen and (max-width:1400px) {
      font-size: 3rem;
    }
  }

  p {
    font-size: 1.5rem;
    color: $white-color;
    font-family: $font-secondary;
    max-width: 600px;

    @media screen and (max-width:1400px) {
      font-size: 1.2rem;
    }
  }




  .timeline-container {
    width: 100%;
    height: 500px;
    display: flex;
    flex-direction: row;
    position: relative;
    align-items: center;
    justify-content: space-between;



    .timeline-vertical {
      display: none;
    }

    @media screen and (max-width:768px) {
      height: 100%;

      .timeline-vertical {
        display: block;
      }
    }

    font-family: Rajdhani,
    sans-serif;

    .timeline-img {
      width: 100%;
    }

    .timeline-img-container {
      width: 100%;

      @media screen and (max-width:768px) {
        display: none;
      }
    }

    .progress {
      width: 100%;
    }

    .timeline-card {
      position: absolute;
      border: 1px solid #00E1FF;
      // height: 159px;
      width: 348px;
      padding: 20px;
      height: fit-content;

      h6 {
        font-size: 24px;
        letter-spacing: 5%;
        font-weight: 700;
      }

      ul {
        padding-left: 20px;
      }


      @media screen and (max-width:768px) {
        width: 148px;
        padding: 8px;
      }

    }

    .timeline-end {
      @media screen and (max-width:768px) {
        display: none;
      }
    }

    .timeline-start {
      @media screen and (max-width:768px) {
        display: none;
      }
    }

    @media screen and (max-width:768px) {
      flex-direction: column;
      position: relative;
      align-items: center;
      justify-content: space-between;
      margin: 8rem 0;

    }



    .arrow {
      position: absolute;

      @media screen and (max-width:768px) {
        display: none;
      }
    }

    .card1 {
      top: 40px;

      @media screen and (max-width:768px) {
        top: 32px;
        right: 10px;
      }
    }

    .card2 {
      bottom: 0px;
      left: 25%;

      @media screen and (max-width:768px) {
        left: 0px;
        top: 25%;
      }
    }

    .card3 {
      top: 10px;
      right: 25%;

      @media screen and (max-width:768px) {
        top: 50%;
        right: 0px;
      }
    }

    .card4 {
      bottom: 40px;
      right: 0%;

      @media screen and (max-width:768px) {
        bottom: 70px;
        left: 0px;
      }
    }

    .arrow1 {
      top: 38%;
      left: 11%;
    }

    .arrow2 {
      bottom: 39%;
      left: 36.2%;
    }

    .arrow3 {
      top: 38%;
      right: 37.45%;
      transform: rotate(180deg);
    }

    .arrow4 {
      bottom: 39%;
      right: 12.3%;
    }
  }

}

@-webkit-keyframes LeftToRight {
  0% {
    left: 0;
  }

  50% {
    right: 0;
  }

  100% {
    left: 0;
  }
}

@-webkit-keyframes RightToLeft {
  0% {
    left: 0;
  }

  50% {
    right: 0;
  }

  100% {
    left: 0;
  }
}

.footer_main_container {
  position: absolute;
  width: 100%;
  bottom: 0;
  background: #000000;
  height: 20px;
  text-align: center;

  .copyright {
    color: $white-color;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    text-transform: uppercase;
    font-weight: 400;
    letter-spacing: 0.5px;
    clip-path: polygon(10% 0, 92% 0, 100% 100%, 0 100%);
    height: 38px;
    background-repeat: no-repeat;
    background-size: 100%;
    font-family: $font-secondary;
    background: #000000;
    padding: 0 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: -36px auto 0 auto;
    max-width: 520px;

    @media screen and (max-width:600px) {
      max-width: 90%;
      font-size: 14px;
      padding: 0 2rem;
      margin: -26px auto 0 auto;
    }
  }

}